// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import {initBootstrap} from "./bootstrap.js";
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

// Your app code
console.log(`Hello ${process.env.HELLO}`);

const swiper = new Swiper('.swiper', {
  modules: [ Navigation, Pagination, Autoplay ],
  speed: 400,
  spaceBetween: 0,
  // Optional parameters
  direction: 'horizontal',
  speed: 1000,
  parallax: true,
  loop: true,
  autoplay: {
    delay: 5000,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});
